<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="7">
          <v-img :src=" itemInfo.gallery && itemInfo.gallery[0] && itemInfo.gallery[0].url_thumb || require('@/assets/images/eCommerce/alienware-laptop.jpg')" />
          <v-btn
            v-if="isViewQr"
            color="primary"
            block
            outlined
            class="mt-3"
            @click="showQR = true"
          >
            {{ $t('ViewQRCode') }}
          </v-btn>
        </v-col>
        <v-col

          cols="5"
          class="d-flex flex-column justify-end"
        >
          <v-btn
            v-if="isReturn"
            color="primary"
            block
            class="flex-grow-0"
            @click="showConfirmDialogReturn = true"
          >
            {{ $t('Return') }}
          </v-btn>
          <v-btn
            v-if="isBackList"
            color="primary"
            block
            class="flex-grow-0 mb-2"
            :class="{
              'mb-2' : isBackList && isBorrow
            }"
            @click="showConfirmDialogBackList = true"
          >
            {{ $t('BackList') }}
          </v-btn>
          <v-btn
            v-if="isBorrow"
            color="primary"
            block
            class="flex-grow-0"
            @click="showBorrowDialog = true"
          >
            {{ $t('Borrow') }}
          </v-btn>
          <v-btn
            v-if="canBorrow"
            color="primary"
            outlined
            to="borrow"
          >
            {{ $t('Borrow') }}
          </v-btn>
          <v-btn
            color="warning"
            outlined
            block
            class="mt-4 flex-grow-0"
            @click="handleBack()"
          >
            {{ $t('Back') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text
      v-if="itemInfo.borrow_at"
      class="px-3"
    >
      <v-card
        outlined
        primary
      >
        <v-card-text class="pa-3">
          <p>
            <strong>{{ $t('BeingBorrowedBy') }}:</strong>
            {{ itemInfo.borrower && itemInfo.borrower.full_name }}
          </p>
          <p>
            <b> {{ $t('Phone') }}:</b> {{ itemInfo.borrower && itemInfo.borrower.phone }}
          </p>
          <p>
            <strong>{{ $t('BorrowTime') }}</strong>:
            {{ formatDate(itemInfo.borrow_at) }}
          </p>
          <p>
            <strong>{{ $t('ReturnTime') }}</strong>:
            <!-- {{ formatDate(itemInfo.return_at) }} -->
            <span class="error--text font-weight-semibold">
              {{ timeFromNow(new Date(itemInfo.return_at).getTime() / 1000) }}
            </span>
          </p>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-text>
      <p>
        <strong>{{ $t('Name') }}:</strong>
        {{ itemInfo.title || noContentText }}
      </p>
      <p>
        <strong>{{ $t('Owner') }}:</strong>
        {{ itemInfo.owner && itemInfo.owner.full_name || noContentText }}
      </p>
      <p>
        <strong>{{ $t('SentTime') }}:</strong>
        {{ itemInfo.created_at ? formatDate(new Date(itemInfo.created_at*1000)) : noContentText }}
      </p>
      <p>
        <strong>{{ $t('CanBorrowTo') }}:</strong>
        {{ itemInfo.can_borrow_to ? formatDate(itemInfo.can_borrow_to) : noContentText }}
      </p>
      <p style="white-space: pre-line;">
        <strong>{{ $t('Description') }}:</strong><br />
        {{ itemInfo.description || noContentText }}
      </p>
      <p><strong>Gallery</strong></p>
      <v-row
        no-gutters
        class="align-end"
      >
        <v-col
          v-for="(image,index) in itemInfo.gallery"
          :key="index"
          cols="3"
          class="px-1"
        >
          <v-img
            :src="image.url_thumb"
            :aspect-ratio="1"
            @click="openGallery(index)"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="showBorrowDialog">
      <v-form
        ref="borrowForm"
        @submit.prevent="handleBorrowItem"
      >
        <v-card>
          <v-card-text>
            <v-img :src=" itemInfo.gallery && itemInfo.gallery[0] && itemInfo.gallery[0].url_thumb || require('@/assets/images/eCommerce/alienware-laptop.jpg')" />
          </v-card-text>
          <v-card-text>
            <p>
              <strong>{{ $t('BorrowItem') }}:</strong> <br />
              {{ itemInfo.title || noContentText }}
            </p>
            <p>
              <strong>{{ $t('CanBorrowTo') }}:</strong> <br />
              {{ itemInfo.can_borrow_to ? formatDate(itemInfo.can_borrow_to) : noContentText }}
            </p>
            <p>
              <strong class="d-block mb-2">{{ $t('ReturnTime') }}:</strong>
              <DatePicker
                v-model="returnDate"
                :min-date="returnTimeRange.minDate"
                :max-date="returnTimeRange.maxDate"
                :label="$t('ReturnTime')"
                :rules="[validators.required]"
                readonly
              />
            </p>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="6">
                <v-btn
                  color="primary"
                  block
                  type="submit"
                >
                  {{ $t('Borrow') }}
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  color="warning"
                  block
                  outlined
                  @click="showBorrowDialog = false; handleBack()"
                >
                  {{ $t('Back') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="showBorrowQR">
      <v-card>
        <v-card-text>
          <vue-qr
            :text="qrCodeItem"
            :size="250"
          ></vue-qr>
        </v-card-text>
        <v-card-text>
          <p>
            {{ $t('SuccessfullyRegistered') }}
          </p>
          <p>
            {{ $t('ShowThisQR') }}
          </p>
          <p>
            {{ $t('ReturnBefore') }}: <br />

            {{ formatDate(dialogBorrowData.returnAt) }}
          </p>
          <p>
            {{ $t('BorrowItem') }}: <br />
            <strong>
              {{ itemInfo.title || noContentText }}
            </strong>
          </p>
          <p>
            {{ $t('BeingBorrowedBy') }}: <br />
            <strong>

              {{ currentUser.full_name }}
            </strong>
          </p>
          <p>
            {{ $t('ReturnTime') }}: <span class=""><b>
              {{ timeFromNow(new Date(dialogBorrowData.returnAt).getTime() / 1000) }}

            </b></span>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="warning"
            block
            @click="showBorrowQR = false;handleBack()"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showQR">
      <v-card>
        <v-card-text class="section-to-print">
          <vue-qr
            :text="qrCodeItem"
            :size="250"
          ></vue-qr>
        </v-card-text>
        <v-card-text class="text-center section-to-print">
          <p>
            <span class="text-lg">
              <strong>{{ itemInfo.title || noContentText }} - {{ itemInfo.id_item }}</strong>
            </span>
            <br />
            {{ $t('Owner') }}:
            <strong>{{ itemInfo.owner && itemInfo.owner.full_name || noContentText }}</strong>
          </p>
        </v-card-text>
        <v-card-actions class="d-block">
          <v-btn
            color="primary"
            block
            class=""
            @click="handlePrint"
          >
            {{ $t('Print') }}
          </v-btn>
          <v-btn
            color="warning"
            outlined
            block
            class="mt-4 ml-0"
            @click="showQR = false"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      v-if="showConfirmDialogReturn"
      :title="$t('Return item')"
      :content="$t('Are you sure you want to return this item')"
      @ok="handleReturnItem"
      @cancel="handleBack"
    ></ConfirmDialog>
    <ConfirmDialog
      v-if="showConfirmDialogBackList"
      :title="$t('Back list item')"
      :content="$t('Are you sure you want to back list this item')"
      @ok="handleBackListItem"
      @cancel="handleBack"
    ></ConfirmDialog>
    <LightBox
      v-if="imageSlide.length"
      ref="lightboxRef"
      :media="imageSlide"
      :show-light-box="false"
      :show-thumbs="false"
    ></LightBox>
  </v-card>
</template>
<script>
import { required } from '@/@core/utils/validation'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import DatePicker from '@/components/DatePicker.vue'
import { ACTION_SCAN_QR, ITEM_STATUS } from '@/utils/shareBoxHelper'
import dayjs from 'dayjs'
import LightBox from 'vue-image-lightbox'
import VueQr from 'vue-qr'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: { DatePicker, VueQr, ConfirmDialog, LightBox },
  data() {
    return {
      showBorrowDialog: false,
      showBorrowQR: false,
      showQR: false,
      returnDate: dayjs().add(3, 'day').format('YYYY-MM-DD'),
      itemInfo: {},
      showConfirmDialogReturn: false,
      showConfirmDialogBackList: false,
      dialogBorrowData: {
        idBorrow: '',
        itemId: '',
        returnAt: '',
      },
      validators: {
        required,
      },
    }
  },
  computed: {
    ...mapGetters('sharebox', ['getQrData']),
    ...mapGetters('auth', ['currentUser']),
    noContentText() {
      return this.$t('NoContent')
    },
    qrCodeItem() {
      return this.itemInfo?.status
        ? JSON.stringify({
          id_item: this.$route.params.id,
          id_owner: this.itemInfo.owner?.id_user,
          is_backlist_qr: this.itemInfo?.owner?.id_user === this.currentUser?.id_user,
        })
        : ''
    },

    // qrCodeItemToBorrow() {
    //   return this.dialogBorrowData.itemId && this.dialogBorrowData.idBorrow && this.dialogBorrowData.returnAt
    //     ? JSON.stringify({ ...this.dialogBorrowData })
    //     : ''
    // },
    isBorrow() {
      return this.getQrData?.actionType === ACTION_SCAN_QR.BORROW && !this.itemInfo?.borrow_at && !this.isBackList
    },
    isReturn() {
      return this.getQrData?.actionType === ACTION_SCAN_QR.RETURN && this.itemInfo?.borrow_at
    },
    isBackList() {
      return !this.itemInfo?.borrow_at && this.itemInfo?.id_owner === this.currentUser.id_user
    },
    isViewQr() {
      return this.currentUser?.permission?.manage_share_box && this.$route.meta?.permission === 'manage_share_box'
    },
    canBorrow() {
      return !this.isBorrow && !this.isReturn && !this.isViewQr && this.itemInfo.id_owner !== this.currentUser.id_user
    },
    returnTimeRange() {
      return {
        minDate: this.formatDate(new Date(), 'YYYY-MM-DD'),
        maxDate: this.itemInfo.can_borrow_to
          ? this.formatDate(new Date(this.itemInfo.can_borrow_to), 'YYYY-MM-DD')
          : '',
      }
    },
    imageSlide() {
      return this.itemInfo?.gallery?.length ? this.itemInfo.gallery.map(item => ({ thumb: item.url_thumb, src: item.url })) : []
    },
  },
  created() {
    this.getItemInfo(this.$route.params.id).then(resp => {
      const { result, data } = resp
      if (result === 'success') {
        this.$set(this, 'itemInfo', data)
      }
    })
  },
  beforeDestroy() {
    this.setQrData(null)
  },
  methods: {
    ...mapMutations('sharebox', ['setQrData']),
    ...mapActions('sharebox', ['getItemInfo', 'updateStatusItem', 'fetchStaffOverview']),
    handleBack() {
      if ([ACTION_SCAN_QR.BORROW, ACTION_SCAN_QR.RETURN].includes(this.getQrData?.actionType)) {
        this.$router.go(-2)
      } else {
        this.$router.back()
      }
    },
    handleBorrowItem() {
      const isValidForm = this.$refs.borrowForm.validate()

      if (!isValidForm) {
        this.$store.commit('snackbar/show', {
          message: this.$t('RequiredFillData'),
          type: 'error',
        })

        return
      }

      this.$set(this, 'dialogBorrowData', {
        idBorrow: this.currentUser.id_user,
        itemId: this.$route.params.id,
        returnAt: this.returnDate,
      })

      this.updateStatusItem({
        status: ITEM_STATUS.BORROWED,
        idBorrow: this.currentUser.id_user,
        itemId: this.$route.params.id,
        returnAt: this.returnDate,
      })
        .then(() => {
          // console.log(resp)
          this.showBorrowDialog = false
          this.showBorrowQR = true
          this.fetchStaffOverview()
        })
        .catch(error => {
          console.log(error)
          this.$store.commit('snackbar/show', {
            message: error?.message || 'Somethings wrong',
            type: 'error',
          })
        })
    },
    handleReturnItem() {
      this.updateStatusItem({
        status: ITEM_STATUS.AVAILABLE,
        itemId: this.$route.params.id,

        // idBorrow: this.itemInfo.borrower.id_user,
      })
        .then(() => {
          this.handleBack()
          this.fetchStaffOverview()
        })
        .catch(error => {
          console.log(error)
          this.$store.commit('snackbar/show', {
            message: error?.message || 'Somethings wrong',
            type: 'error',
          })
        })
    },
    handleBackListItem() {
      this.updateStatusItem({
        status: ITEM_STATUS.RETURN,
        itemId: this.$route.params.id,

        // idBorrow: this.itemInfo.borrower.id_user,
      })
        .then(() => {
          this.handleBack()
        })
        .catch(error => {
          console.log(error)
          this.$store.commit('snackbar/show', {
            message: error?.message || 'Somethings wrong',
            type: 'error',
          })
        })
    },
    openGallery(index) {
      this.$refs.lightboxRef.showImage(index)
    },
    handlePrint() {
      try {
        // Print for Safari browser
        document.execCommand('print', false, null)
      } catch {
        window.print()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
img {
  max-width: 100%;
  border: 1px solid #ccc;
  display: block !important;
  margin: 0 auto;
}
.section-to-print img {
  width: 100%;
}

@media print {
  body * {
    visibility: hidden !important;
  }
  .section-to-print,
  .section-to-print * {
    visibility: visible !important;
  }
}
</style>
